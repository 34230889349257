import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531.401 558.064">
		<path
			opacity=".35"
			fill="#D38435"
			d="M504.6 44.132H359.9c-54.2 0-98.1 43.4-98.1 96.9 0 53.5-43.9 96.9-98.1 96.9-83.2 0-150.7 66.6-150.7 148.8v65.8h3.3v64.4c0 5.5 4.4 9.9 9.9 9.9H518.2c5.5 0 9.9-4.4 9.9-9.9v-64.4h3.3V70.132c-.9-14.3-12.5-26-26.8-26z"
		/>
		<path
			fill="#FFF"
			d="M13.2 513.832h491.2c5.5 0 9.9-4.4 9.9-9.9v-124.9H3.3v124.9c0 5.5 4.4 9.9 9.9 9.9z"
		/>
		<path
			fill="#353535"
			d="M485.4 365.132h2v148.7h-2zM455 365.132h2v148.7h-2zM424.7 365.132h2v148.7h-2zM394.4 365.132h2v148.7h-2zM364 365.132h2v148.7h-2zM333.7 365.132h2v148.7h-2zM303.3 365.132h2v148.7h-2zM273 365.132h2v148.7h-2zM242.6 365.132h2v148.7h-2zM212.3 365.132h2v148.7h-2zM181.9 365.132h2v148.7h-2zM151.6 365.132h2v148.7h-2zM121.3 365.132h2v148.7h-2zM90.9 365.132h2v148.7h-2zM60.6 365.132h2v148.7h-2zM30.2 365.132h2v148.7h-2z"
		/>
		<path
			fill="#353535"
			d="M451 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2H451c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 1.9 4.2 4.2 4.2zM420.3 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 2 4.2 4.2 4.2zM330.8 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 1.9 4.2 4.2 4.2zM268.2 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c0 2.3 1.9 4.2 4.2 4.2zM239.8 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 1.9 4.2 4.2 4.2zM178.1 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 1.9 4.2 4.2 4.2zM118.4 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c.1 2.3 1.9 4.2 4.2 4.2zM88.1 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c0 2.3 1.9 4.2 4.2 4.2zM56.8 481.032h9.6c2.3 0 4.2-1.9 4.2-4.2v-65.3c0-2.3-1.9-4.2-4.2-4.2h-9.6c-2.3 0-4.2 1.9-4.2 4.2v65.3c0 2.3 1.9 4.2 4.2 4.2z"
		/>
		<path
			fill="#103141"
			d="M248.8 128.132c0-53.5 43.9-96.9 98.1-96.9h144.7c14.3 0 25.9 11.6 25.9 25.9V439.532H0v-65.8c0-82.2 67.5-148.8 150.7-148.8 54.2.1 98.1-43.3 98.1-96.8z"
		/>
	</svg>
);
