import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Icon from '../components/Icon';
import Guitar from '../components/icons/Guitar';
import Guitar2 from '../components/icons/Guitar2';
import Violin from '../components/icons/Violin';
import Drums from '../components/icons/Drums';
import Mic1 from '../components/icons/Mic1';
import Mic2 from '../components/icons/Mic2';
import Piano from '../components/icons/Piano';
import Rassel from '../components/icons/Rassel';
import Ring from '../components/icons/Ring';

const icons = [Mic1, Guitar, Drums, Piano, Guitar2, Mic2, Rassel, Violin, Ring];

class IconBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { pos: Math.round(Math.random() * (icons.length - 1)) };
    this.interval = false;
  }
  componentDidMount() {
    this.interval = window.setInterval(this.loop, 720);
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  render() {
    const finalIcons = icons.map((icon, i) => icons[(i + this.state.pos) % icons.length]);
    return (
      <div
        css={css`
          position: relative;
          z-index: 1;
          pointer-events: none;
          padding: 0 1em;
          display: flex;
          justify-content: space-between;
          margin: -0.5em 0;
        `}
      >
        {finalIcons.map((MyIcon, i) => {
          return (
            <Icon key={i}>
              <MyIcon />
            </Icon>
          );
        })}
      </div>
    );
  }
  loop = () => {
    this.setState({
      pos: Math.round(Math.random() * (icons.length - 1)),
    });
  };
}

export default IconBar;
