import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531.401 558.064">
		<path
			opacity=".35"
			fill="#D38435"
			d="M342.248 308.325l.3-2c2-20.8-7.1-24.9-15.5-24.3-6.6.4-12.6 3.8-16.9 8.8-10.4 12.4-19 10.1-24.4 5.7v-195.4c1.6-4.7 4.4-11 8.5-16.1 1.2-1.5 1.5-3.6.7-5.4-.4-1-1-2.2-1.5-3.5h4v1.8c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v1.8h-6c-1.7-4.4-3.4-9.8-4.8-15.8h6.4v1.8c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v1.8h-7.4c-.9-4.9-1.5-10.1-1.4-15.2h6.3v1.8c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v1.8h-6.1c.3-3.5.9-6.9 2-10.1.3-1-.5-2-1.5-2h-28c-1 0-1.8 1-1.5 2 1.1 3.2 1.6 6.6 2 10.1h-6.1v-1.8c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-1.8h6.3c0 5.1-.5 10.3-1.4 15.2h-7.4v-1.8c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-1.8h6.4c-1.3 5.4-2.9 10.4-4.4 14.6h-6.4v-1.8c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c0 .9.8 1.7 1.7 1.7h4.7c.9 0 1.7-.8 1.7-1.7v-1.8h4.5c-.8 1.9-1.5 3.5-2 4.7-.8 1.8-.5 3.9.7 5.4 4.2 5.1 6.9 11.4 8.5 16.1v195.4c-5.5 4.4-14 6.7-24.4-5.7-4.2-5-10.3-8.4-16.9-8.8-8.4-.5-17.5 3.5-15.5 24.3l.3 2c5.3 32.7-1.8 66.1-19.1 94.4-17.8 29.2-31.4 71.8 4.1 110.4 9.9 10.8 22.2 19.3 35.7 25.2 12.8 5.6 31.1 11.5 50.6 10.9 19.5.6 37.8-5.3 50.6-10.9 13.5-5.9 25.7-14.4 35.7-25.2 35.4-38.6 21.9-81.3 4.1-110.4-17.3-28.3-24.4-61.7-19.1-94.4z"
		/>
		<path
			fill="#93291D"
			d="M170.096 393.196c17.3-28.3 24.3-61.7 19.1-94.4l-.3-2c-2-20.8 7.1-24.9 15.5-24.3 6.6.4 12.6 3.8 16.9 8.8 18.5 22 31.1-2.3 31.1-2.3h16.4s12.6 24.3 31.1 2.3c4.2-5 10.3-8.4 16.9-8.8 8.4-.5 17.5 3.5 15.5 24.3l-.3 2c-5.3 32.7 1.8 66.1 19.1 94.4 17.8 29.2 31.4 71.8-4.1 110.4-9.9 10.8-22.2 19.3-35.7 25.2-12.8 5.6-31.1 11.5-50.6 10.9-19.5.6-37.8-5.3-50.6-10.9-13.5-5.9-25.7-14.4-35.7-25.2-35.7-38.6-22.2-81.2-4.3-110.4z"
		/>
		<path fill="#FFF" d="M238.296 20.896h11.5v4.9h-11.5z" />
		<path
			fill="#FFF"
			d="M234.396 29.296h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c.1.9.8 1.7 1.7 1.7zM270.927 20.878h11.5v4.9h-11.5z"
		/>
		<path
			fill="#FFF"
			d="M286.396 29.296h-4.7c-.9 0-1.7-.8-1.7-1.7v-8.5c0-.9.8-1.7 1.7-1.7h4.7c.9 0 1.7.8 1.7 1.7v8.5c-.1.9-.8 1.7-1.7 1.7zM231.396 60.496h11.5v4.9h-11.5z"
		/>
		<path
			fill="#FFF"
			d="M227.496 68.896h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c0 .9.7 1.7 1.7 1.7zM277.883 61.651h11.5v4.9h-11.5z"
		/>
		<path
			fill="#FFF"
			d="M293.296 70.096h-4.7c-.9 0-1.7-.8-1.7-1.7v-8.5c0-.9.8-1.7 1.7-1.7h4.7c.9 0 1.7.8 1.7 1.7v8.5c0 .9-.7 1.7-1.7 1.7zM235.796 40.996h11.5v4.9h-11.5z"
		/>
		<path
			fill="#FFF"
			d="M231.896 49.396h4.7c.9 0 1.7-.8 1.7-1.7v-8.5c0-.9-.8-1.7-1.7-1.7h-4.7c-.9 0-1.7.8-1.7 1.7v8.5c0 1 .8 1.7 1.7 1.7zM273.512 41.023h11.5v4.9h-11.5z"
		/>
		<path
			fill="#FFF"
			d="M288.896 49.396h-4.7c-.9 0-1.7-.8-1.7-1.7v-8.5c0-.9.8-1.7 1.7-1.7h4.7c.9 0 1.7.8 1.7 1.7v8.5c0 1-.8 1.7-1.7 1.7z"
		/>
		<path fill="#FFC611" d="M245.496 80.496h29.7v270.6h-29.7z" />
		<path
			fill="#FFC611"
			d="M244.896 10.796c-.3-1 .5-2 1.5-2h28c1 0 1.8 1 1.5 2-7 21.6 4.4 50.1 8.6 59.3.8 1.8.5 3.9-.7 5.4-7.7 9.4-10.5 23-10.5 23h-25.6s-2.9-13.6-10.5-23c-1.2-1.5-1.5-3.6-.7-5.4 4-9.2 15.4-37.7 8.4-59.3zM225.196 473.396h70.3c2.6 0 4.7-2.1 4.7-4.7v-6.3c0-2.6-2.1-4.7-4.7-4.7h-70.3c-2.6 0-4.7 2.1-4.7 4.7v6.3c.1 2.6 2.2 4.7 4.7 4.7z"
		/>
		<ellipse fill="#FFF" cx="263.14" cy="377.299" rx="37.2" ry="37.2" />
		<circle fill="#353535" cx="263.196" cy="377.296" r="28.1" />
	</svg>
);
