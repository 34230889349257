import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531.401 558.064">
		<path
			opacity=".35"
			fill="#D38435"
			d="M166.146 452.454c0 57.8 46.5 104.8 104.2 105.6 58.2.8 106.4-46.3 107-104.6.2-19.2-4.8-37.3-13.6-52.9-10.9-19.3-16-41.4-13.6-63.4.2-1.8.3-3.6.3-5.5 0-29.6-26.1-54.4-61-60.8v-167.9c4.3-.8 7.6-4.5 7.6-9v-10.5h8.1v4.5h2.8c4.5 0 8.1-3.6 8.1-8.1s-3.6-8.1-8.1-8.1h-2.8v4.5h-8.1v-13.6h8.1v4.5h2.8c4.5 0 8.1-3.6 8.1-8.1s-3.6-8.1-8.1-8.1h-2.8v4.5h-8.1v-13.6h8.1v4.5h2.8c4.5 0 8.1-3.6 8.1-8.1s-3.6-8.1-8.1-8.1h-2.8v4.5h-8.1v-14.5c0-5-4.1-9.1-9.1-9.1h-32.1c-5 0-9.1 4.1-9.1 9.1v14.5h-8.1v-4.5h-2.8c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1h2.8v-4.5h8.1v13.6h-8.1v-4.5h-2.8c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1h2.8v-4.5h8.1v13.6h-8.1v-4.5h-2.8c-4.5 0-8.1 3.6-8.1 8.1s3.6 8.1 8.1 8.1h2.8v-4.5h8.1v10.5c0 4.5 3.3 8.2 7.6 9v168c-35 6.3-61 31.1-61 60.8 0 1.8.1 3.7.3 5.5 2.4 21.8-2.4 43.8-13.3 62.7-9.1 15.4-14.2 33.3-14.2 52.5z"
		/>
		<path
			fill="#F25F26"
			d="M242.049 66.4h35.2v278.8h-35.2zM222.949 23.6h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M223.549 35.4h2.8V19.2h-2.8c-4.5 0-8.1 3.6-8.1 8.1 0 4.4 3.6 8.1 8.1 8.1zM222.949 65.4h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M223.549 77.2h2.8V61h-2.8c-4.5 0-8.1 3.6-8.1 8.1 0 4.4 3.6 8.1 8.1 8.1zM222.949 44.5h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M223.549 56.3h2.8V40.1h-2.8c-4.5 0-8.1 3.6-8.1 8.1 0 4.4 3.6 8.1 8.1 8.1zM278.882 23.58h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M295.649 35.4h-2.8V19.2h2.8c4.5 0 8.1 3.6 8.1 8.1 0 4.4-3.6 8.1-8.1 8.1zM278.882 65.41h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M295.649 77.2h-2.8V61h2.8c4.5 0 8.1 3.6 8.1 8.1 0 4.4-3.6 8.1-8.1 8.1zM278.882 44.495h17.3v7.3h-17.3z"
		/>
		<path
			fill="#F25F26"
			d="M295.649 56.3h-2.8V40.1h2.8c4.5 0 8.1 3.6 8.1 8.1 0 4.4-3.6 8.1-8.1 8.1z"
		/>
		<path
			fill="#00B7B4"
			d="M167.949 389c10.9-19 15.7-41 13.3-62.7-.2-1.8-.3-3.6-.3-5.5 0-34.4 35.2-62.4 78.7-62.4 43.4 0 78.7 27.9 78.7 62.4 0 1.8-.1 3.7-.3 5.5-2.4 22 2.7 44.1 13.6 63.4 8.8 15.6 13.8 33.7 13.6 52.9-.6 58.2-48.8 105.3-107 104.6-57.7-.8-104.2-47.7-104.2-105.6 0-19.2 5-37.1 13.9-52.6z"
		/>
		<circle fill="#F25F26" cx="259.549" cy="387.3" r="42.2" />
		<circle fill="#353535" cx="259.549" cy="387.3" r="33.1" />
		<path
			fill="#FFF"
			d="M268.749 83.9h1.1v397.5h-1.1zM249.349 83.9h1.1v397.5h-1.1zM259.049 83.9h1.1v397.5h-1.1z"
		/>
		<path
			fill="#F25F26"
			d="M228.549 492.2h62.1c2.8 0 5-2.2 5-5v-6.8c0-2.8-2.2-5-5-5h-62.1c-2.8 0-5 2.2-5 5v6.8c0 2.8 2.2 5 5 5z"
		/>
		<path
			fill="#00B7B4"
			d="M243.549 92.3h32.1c5 0 9.1-4.1 9.1-9.1V9.1c0-5-4.1-9.1-9.1-9.1h-32.1c-5 0-9.1 4.1-9.1 9.1v74.1c0 5 4.1 9.1 9.1 9.1z"
		/>
		<path
			fill="#FFF"
			d="M269.249 77.2c2.2 0 3.9-1.8 3.9-3.9V18.2c0-2.2-1.8-3.9-3.9-3.9-2.2 0-3.9 1.8-3.9 3.9v55.1c0 2.1 1.8 3.9 3.9 3.9zM249.949 77.2c2.2 0 3.9-1.8 3.9-3.9V18.2c0-2.2-1.8-3.9-3.9-3.9-2.2 0-3.9 1.8-3.9 3.9v55.1c-.1 2.1 1.7 3.9 3.9 3.9z"
		/>
	</svg>
);
