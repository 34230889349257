import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Bungee from '../components/Bungee';

class FelsVideo extends PureComponent {
  render() {
    return (
      <section
        css={css`
          background: #3439ef;
          position: relative;
        `}
      >
        <h1
          css={css`
            position: absolute;
            top: 1em;
            left: 1em;
            font-size: 80%;
            margin: 0;
          `}
        >
          <Bungee
            content='Bändruum Video'
            regular='#fff'
            outline='#781d0f'
            shade='#0F2B33'
            css={css`
              display: block;
              line-height: 0.5;
            `}
          />
          <Bungee
            content='Fels'
            regular='#efe334'
            outline='#781d0f'
            shade='#0F2B33'
            css={css`
              font-size: 79%;
              line-height: 0.5;
            `}
          />
        </h1>
        <video
          src='/fels-singles.webm'
          controls
          css={css`
            width: 100%;
            max-height: 100vh;
            aspect-ratio: 9 / 16;
          `}
        />
      </section>
    );
  }
}

export default FelsVideo;
