/** @jsx jsx */
import { Global, css, jsx } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';

import Intro from './sections/Intro';
import IconBar from './sections/IconBar';
import Vogel from './sections/Vogel';
import Crew from './sections/Crew';
import Mailchimp from './sections/Mailchimp';
import Footer from './sections/Footer';
import NgxVideo from './sections/NgxVideo';
import FelsVideo from './sections/FelsVideo';

const theme = {
  color: {
    fg: 'black',
    bg: 'white',
  },
  mixin: {
    section: css`
      padding: 1ex 1em 0.4ex;
    `,
  },
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          @font-face {
            font-family: 'Bungee Regular';
            src: url('./font/BungeeLayers/BungeeLayers-Regular.eot?#ie') format('embedded-opentype'),
              url('./font/BungeeLayers/BungeeLayers-Regular.woff2') format('woff2'),
              url('./font/BungeeLayers/BungeeLayers-Regular.woff') format('woff'),
              url('./font/BungeeLayers/BungeeLayers-Regular.svg#font') format('svg');
          }

          @font-face {
            font-family: 'Bungee Outline';
            src: url('./font/BungeeLayers/BungeeLayers-Outline.eot?#ie') format('embedded-opentype'),
              url('./font/BungeeLayers/BungeeLayers-Outline.woff2') format('woff2'),
              url('./font/BungeeLayers/BungeeLayers-Outline.woff') format('woff'),
              url('./font/BungeeLayers/BungeeLayers-Outline.svg#font') format('svg');
          }

          @font-face {
            font-family: 'Bungee Inline';
            src: url('./font/BungeeLayers/BungeeLayers-Inline.eot?#ie') format('embedded-opentype'),
              url('./font/BungeeLayers/BungeeLayers-Inline.woff2') format('woff2'),
              url('./font/BungeeLayers/BungeeLayers-Inline.woff') format('woff'),
              url('./font/BungeeLayers/BungeeLayers-Inline.svg#font') format('svg');
          }

          @font-face {
            font-family: 'Bungee Shade';
            src: url('./font/BungeeLayers/BungeeLayers-Shade.eot?#ie') format('embedded-opentype'),
              url('./font/BungeeLayers/BungeeLayers-Shade.woff2') format('woff2'),
              url('./font/BungeeLayers/BungeeLayers-Shade.woff') format('woff'),
              url('./font/BungeeLayers/BungeeLayers-Shade.svg#font') format('svg');
          }

          html,
          body {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
          }

          body {
            font-size: 5vw;
            font-family: 'Bungee Regular';
          }
        `}
      />
      <Intro />
      <IconBar />
      {/*<Vogel />*/}
      <NgxVideo />
      <IconBar />
      <FelsVideo />
      <IconBar />
      <Crew />
      <IconBar />
      <Mailchimp />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
