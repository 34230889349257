import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Bungee from '../components/Bungee';

class Vogel extends PureComponent {
	render() {
		return (
			<section
				css={theme => [
					theme.mixin.section,
					css`
						background: #27849e;
					`
				]}
			>
				<div
					css={theme => [
						theme.mixin.section,
						css`
							background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Eurasian_Coot.jpg/640px-Eurasian_Coot.jpg);
							background-position: center;
							background-repeat: no-repeat;
							background-size: 100%;
							max-width: 10em;
							margin: 0 auto;
						`
					]}
				>
					<h1
						css={css`
							font-size: 80%;
							margin: 0;
						`}
					>
						<Bungee
							content="Aktuell"
							regular="#fff"
							outline="#781d0f"
							shade="#0F2B33"
							css={css`
								display: block;
								line-height: 0.5;
							`}
						/>
						<Bungee
							content="7. Juni 19"
							regular="#fff"
							outline="#781d0f"
							shade="#0F2B33"
							css={css`
								margin-bottom: 7em;
								font-size: 89%;
								line-height: 0.5;
							`}
						/>
						<Bungee
							content="Bläss spielt"
							regular="#fff"
							outline="#781d0f"
							shade="#0F2B33"
							css={css`
								display: block;
								font-size: 70%;
							`}
						/>
						<Bungee
							content="im Vogel"
							regular="#fff"
							outline="#781d0f"
							shade="#0F2B33"
							css={css`
								display: block;
								font-size: 70%;
							`}
						/>
					</h1>
					<Bungee
						content="Entlisbergstrasse 26, 8038 Zürich"
						outline="#0F2B33"
						regular="#fff"
						css={css`
							display: block;
							text-align: right;
							font-size: 60%;
						`}
					/>
				</div>
				<Bungee
					content="Bild: https://commons.wikimedia.org/wiki/File:Eurasian_Coot.jpg"
					inline="#fff"
					css={css`
						font-size: 0.8rem;
					`}
				/>
			</section>
		);
	}
}

export default Vogel;
