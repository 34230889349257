import { Fragment, PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Bungee from '../components/Bungee';

const Member = ({ name, instruments }) => (
	<Fragment>
		<dt
			css={css`
				margin-top: 0.2em;
			`}
		>
			<Bungee content={name} regular="#0F2B33" />
		</dt>
		<dd
			css={css`
				margin-bottom: 0.2em;
			`}
		>
			<Bungee content={instruments} regular="#B2A52F" />
		</dd>
	</Fragment>
);

class Crew extends PureComponent {
	render() {
		return (
			<section
				css={theme => [
					theme.mixin.section,
					css`
						padding-top: 2em;
						padding-bottom: 2em;
						background: #efe334;
						display: flex;
					`
				]}
			>
				<h1
					css={css`
						font-size: 100%;
						margin: 0 0.4em 0;
						writing-mode: vertical-rl;
						text-orientation: upright;
					`}
				>
					<Bungee
						content="Besetzung"
						regular="#fff"
						outline="#0F2B33"
						shade="#0F2B33"
						css={css`
							display: block;
							padding: 0.4em 0;
							text-align: center;
							font-size: 200%;
							background-color: #124a57;
							border-radius: 1em;
							box-shadow: -0.1em 0.1em #0f2b33;
							border: 0.02em solid #0f2b33;
						`}
					/>
				</h1>
				<dl
					css={css`
						margin: 1em 0;
						display: flex;
						flex-flow: column;
						justify-content: space-around;
						@media (min-width: 30em) {
							font-size: 80%;
						}
					`}
				>
					<Member
						name="Angela HoiSie"
						instruments="Upright Bass / Violin / Vocals"
					/>
					<Member name="Jakob Zogg" instruments="Vocals / Guitar / Bluesharp" />
					<Member name="Jon" instruments="Guitar / Pedal Steel" />
					<Member name="Meret" instruments="Vocals / Piano / Percussion" />
					<Member name="Obi Sue" instruments="Drums" />
				</dl>
			</section>
		);
	}
}

export default Crew;
