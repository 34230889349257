import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import MailchimpForm from 'react-mailchimp-form';

import Bungee from '../components/Bungee';

class Mailchimp extends PureComponent {
	render() {
		return (
			<section css={theme => theme.mixin.section}>
				<div
					css={css`
						display: flex;
						justify-content: space-around;
					`}
				>
					<div
						css={css`
							z-index: 2;
							writing-mode: vertical-rl;
							text-orientation: upright;
							display: block;
							padding: 0.4em 0 0.4em 0.2em;
							text-align: center;
							font-size: 80%;
							background-color: #dc3e17;
							border-bottom-left-radius: 1em;
							border-top-left-radius: 1em;
							box-shadow: -0.1em 0.1em #0f2b33;
						`}
					>
						<Bungee
							content="Newsletter"
							regular="#fff"
							outline="#0F2B33"
							shade="#0F2B33"
						/>
					</div>
					<div
						css={css`
							background-color: #dc3e17;
							position: relative;
							z-index: 1;
							box-shadow: -0.1em 0.1em #0f2b33;
							margin: 0 -0.1em;
							padding: 1em 0;
							width: 100%;
							box-sizing: border-box;

							& form {
								margin: 0;
								padding: 0 1em;
								min-height: 100%;
								display: flex;
								flex-flow: column nowrap;
								justify-content: space-between;
							}

							& input {
								text-align: center;
								font: inherit;
								font-size: 60%;
								border: 2px solid black;
								padding: 1ex 1em;
								margin: 0.1em 0;
								box-sizing: border-box;
								width: 100%;
								box-shadow: -0.1em 0.1em #0f2b33;
							}

							& button {
								appearance: none;
								font: inherit;
								font-size: 60%;
								border: 2px solid black;
								padding: 1ex 1em;
								margin: 0.1em 0;
								box-sizing: border-box;
								width: 100%;
								box-shadow: -0.1em 0.1em #0f2b33;
								background: #efe334;
							}

							& .msg-alert {
								background: white;
								font-size: 1rem;
								text-align: center;
								border-radius: 1em;

								& p {
									padding: 0.2em;
								}
							}
						`}
					>
						<MailchimpForm
							action="http://flxlabs.us7.list-manage.com/subscribe/post?u=120706a941b7e981082352d8f&amp;id=75349ef171"
							fields={[
								{
									name: 'EMAIL',
									placeholder: 'Email',
									type: 'email',
									required: true
								},
								{
									name: 'FNAME',
									placeholder: 'Vorname',
									type: 'text',
									required: false
								},
								{
									name: 'LNAME',
									placeholder: 'Name',
									type: 'text',
									required: false
								}
							]}
						/>
					</div>
					<div
						css={css`
							writing-mode: vertical-rl;
							text-orientation: upright;
							display: block;
							padding: 0.4em 0.2em 0.4em 0;
							text-align: center;
							font-size: 80%;
							background-color: #dc3e17;
							border-bottom-right-radius: 1em;
							border-top-right-radius: 1em;
							box-shadow: -0.1em 0.1em #0f2b33;
						`}
					>
						<Bungee
							content="abonnieren"
							regular="#fff"
							outline="#0F2B33"
							shade="#0F2B33"
						/>
					</div>
				</div>
			</section>
		);
	}
}

export default Mailchimp;
