import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

const Span = ({ content, styl, color, displayed }) => {
	if (!color) return null;
	const fontCss = css`
		font-family: Bungee ${styl};
		font-weight: normal;
		font-style: normal;
		color: ${color};
	`;
	if (displayed) {
		return <span css={fontCss}>{content}</span>;
	}
	return (
		<span
			css={[
				fontCss,
				css`
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					
					&:before {content: "${content}"}
				`
			]}
		/>
	);
};

class Bungee extends PureComponent {
	render() {
		const { content, regular, inline, outline, shade, ...props } = this.props;
		const displayed = shade
			? 'shade'
			: outline
			? 'outline'
			: regular
			? 'regular'
			: inline
			? 'inline'
			: false;
		if (displayed === false) return null;
		return (
			<div
				css={css`
					display: inline-block;
					position: relative;
				`}
				{...props}
			>
				<Span
					content={content}
					styl="Shade"
					color={shade}
					displayed={displayed === 'shade'}
				/>
				<Span
					content={content}
					styl="Outline"
					color={outline}
					displayed={displayed === 'outline'}
				/>
				<Span
					content={content}
					styl="Regular"
					color={regular}
					displayed={displayed === 'regular'}
				/>
				<Span
					content={content}
					styl="Inline"
					color={inline}
					displayed={displayed === 'inline'}
				/>
			</div>
		);
	}
}

export default Bungee;
