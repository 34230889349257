import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

class Icon extends PureComponent {
	render() {
		const { children, ...props } = this.props;
		return (
			<div
				css={css`
					display: block;
					background-color: #fff;
					border-radius: 50%;
					width: 1em;
					height: 1em;
					padding: 0.2em;
					margin: 0.1em;
				`}
				{...props}
			>
				{children}
			</div>
		);
	}
}

export default Icon;
