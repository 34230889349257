import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Bungee from '../components/Bungee';

class Intro extends PureComponent {
  render() {
    return (
      <div
        css={(theme) => [
          theme.mixin.section,
          css`
            padding-top: 1.4em;
            padding-bottom: 2em;
            background: #dd3d19;
            border-top-left-radius: 1em;
            border-top-right-radius: 1em;
          `,
        ]}
      >
        <h1
          css={css`
            font-size: 400%;
            margin: 0;
            @media (min-width: 30em) {
              line-height: 1;
            }
          `}
        >
          <div
            css={css`
              position: relative;
              transform: rotate(-2deg);
            `}
          >
            <div
              css={css`
                position: absolute;
              `}
            >
              <Bungee
                content='████'
                inline='#781d0f'
                regular='#fff'
                outline='#781d0f'
                shade='#781d0f'
                css={css`
                  font-size: 90%;
                `}
              />
            </div>
            <Bungee
              content='Bläss'
              inline='#781d0f'
              regular='#fff'
              outline='#781d0f'
              shade='#781d0f'
              css={css`
                margin-left: 0.5em;
              `}
            />
          </div>
        </h1>
        <Bungee
          content='Neominnesang'
          outline='#781d0f'
          regular='#fff'
          css={css`
            display: block;
            text-align: center;
            margin-top: 1em;
            @media (min-width: 30em) {
              font-size: 60%;
            }
          `}
        />
      </div>
    );
  }
}

export default Intro;
